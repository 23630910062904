import React, { useState } from 'react';
import axios from 'axios';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

const SupportersSubscribeEmail = () => {
  const [isVisibleText, setIsVisibleText] = useState(false);

  const [form] = Form.useForm();

  const handleSubmitClick = async (values) => {
    await axios
      .post(`${process.env.GATSBY_API_URL}/supporters-email-subscriptions`, {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
      })
      .then((res) => {
        setIsVisibleText(true);
        setTimeout(() => {
          setIsVisibleText(false);
        }, 5000);
      })
      .catch((err) => console.error(err));

    form.resetFields();
  };

  return (
    <>
      {isVisibleText ? (
        <div className="supporters-thank-you-section">
          <div className="heading">Thank you!</div>
          <div className="description">
            Thank you for your interest. We will contact you soon.
          </div>
        </div>
      ) : (
        <div className="supporters-subscribe-email-section">
          <Row className="supporters-subscribe-email">
            <Col className="description-wrapper" span={12}>
              <div className="heading _section-label">
                Join the Wan PNG journey
              </div>
              <div className="description">
                Wan PNG is a collective vision, in which diverse elements of the
                nation come together to form a community dedicated to creating a
                brighter future for Papua New Guineans.
                <br />
                <br />
                We need the support of fellow visionaries such as you. Consider
                joining Wan PNG as a sponsor today. Financial commitments are
                welcome but they are not the only way you can help. We welcome
                support in many forms:
              </div>

              <ul className="list-container">
                <li className="list-items">
                  Sponsor a student or trainee with an internship
                </li>
                <li className="list-items">
                  Share Wan PNG with your customers or audience
                </li>
                <li className="list-items">
                  Develop helpful content for our community
                </li>
                <li className="list-items">Contribute to our development</li>
              </ul>

              <span className="label">Wan PNG. Wan Community.</span>
            </Col>
            <Col className="form-wrapper" span={12}>
              <span className="form-description">
                Contact Wan PNG today to find out how you can make a
                contribution!
              </span>
              <Form form={form} onFinish={handleSubmitClick}>
                <Row gutter={20}>
                  <Col span={12}>
                    <span className="form-label">First name</span>
                    <Form.Item
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your first name.',
                        },
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <span className="form-label">Last name</span>
                    <Form.Item
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your last name.',
                        },
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </Col>
                </Row>

                <Col>
                  <span className="form-label">Email address</span>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'Please input your email.' },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                    ]}
                  >
                    <Input className="form-email" />
                  </Form.Item>
                </Col>

                <Col>
                  <Button type="primary" htmlType="submit" className="send-btn">
                    Send
                  </Button>
                </Col>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default SupportersSubscribeEmail;
