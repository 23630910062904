import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '@components/Image';

import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';
import CorporateSupporters from '@src/components/CorporateSupporters';
import PlatformPartners from '@components/PlatformPartners';
import OfficialNewsMediaPartners from '@components/OfficialNewsMediaPartners';
import SupportersSubscribeEmail from '@src/modules/SupportersSubscribeEmail';

import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const About = () => {
  const { banner, obstacles, kumul, allDataJson } = useStaticQuery(
    graphql`
      {
        banner: file(relativePath: { eq: "we-are-wanpng-banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              layout: FULL_WIDTH
            )
          }
        }
        obstacles: file(relativePath: { eq: "obstacles-to-prosperity.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 530
              height: 463
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
        kumul: file(relativePath: { eq: "kumul.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              width: 300
              quality: 100
              layout: CONSTRAINED
            )
          }
        }
        allDataJson {
          nodes {
            title
            description
            id
            src {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  height: 565
                  width: 565
                  quality: 100
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    `
  );

  const image = getImage(banner);
  const bgImage = convertToBgImage(image);

  return (
    <Layout isMobileComingSoon={false}>
      <SEO title="About" description="About" />
      <div className="about-section">
        <BackgroundImage
          {...bgImage}
          className="parallax-wrapper"
          style={{
            backgroundAttachment: 'fixed',
            backgroundPosition: '',
            backgroundSize: '',
          }}
        />

        <div className="info-container">
          <div className="info">
            <div className="subheading-container">
              <span className="subheading _section-label">
                The Wan PNG community is dedicated to expanding opportunities
                for local Papua New Guineans.
              </span>
            </div>
            <div className="description-container">
              <p className="description">
                Our goal is to increase the development and employment of local
                Papua New Guinean talent, so everyone in our great nation can
                share in newfound resources and growing prosperity.
                <br />
                <br />
                Under the wantok system, every new Papua New Guinean employed
                positively impacts the lives of many in direct and indirect
                family units, so increased prosperity from employment has far
                reaching effects.
              </p>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
          <div className="content">
            <div className="obstacles-wrapper">
              <div className="obstacles-image">
                <GatsbyImage
                  image={obstacles?.childImageSharp?.gatsbyImageData}
                  alt="Wan PNG"
                />
              </div>
            </div>
            <div className="obstacle-container">
              <div className="title-label _section-label">
                Obstacles to prosperity
              </div>
              <ul className="list-container">
                <li className="list-items">
                  Shortage of valuable employment opportunities for national
                  citizens
                </li>
                <li className="list-items">
                  Unclear pathways to gainful employment
                </li>
                <li className="list-items">
                  Shortage of training and up-skilling opportunities
                </li>
                <li className="list-items">
                  Lack of inclusion and meaningful opportunities for females and
                  disadvantaged persons
                </li>
                <li className="list-items">
                  Need for greater education for job readiness
                </li>
                <li className="list-items">
                  Need for actionable data to help direct school leavers into
                  trades and professions required by industry
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="goal-wrapper">
          <div className="goal">
            <div className="title _section-label">Know our goals</div>

            <div className="info-card-container">
              {allDataJson.nodes.map((data) => (
                <div className="info-card" key={data?.id}>
                  <Image
                    image={data?.src?.childImageSharp?.gatsbyImageData}
                    loading="eager"
                  />
                  <div className="info-wrapper">
                    <div className="title-label">{data?.title}</div>
                    <div className="description-label">{data?.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <SupportersSubscribeEmail />

        <PlatformPartners onSection />
        <OfficialNewsMediaPartners onSection />
        <CorporateSupporters onSection />

        <div className="initiative-wrapper">
          <div className="initiative">
            <div className="heading _section-label">
              A Kumul Petroleum Initiative
            </div>
            <Row gutter={2}>
              <Col span={8}>
                <GatsbyImage
                  image={kumul?.childImageSharp?.gatsbyImageData}
                  className="kumul-logo"
                  alt="Kumul"
                />
              </Col>
              <Col span={16}>
                <div className="description">
                  Kumul Petroleum recognizes its ultimate responsibility as a
                  National Oil Company is to deliver greater prosperity to the
                  people of Papua New Guinea. Our strategic vision for 2020 and
                  beyond includes investment in initiatives that serve to
                  diversify the economy beyond the extractive industry. Wan PNG
                  is a part of this strategy, building a foundation for
                  broadening employment opportunity across regions and industry
                  sectors.
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
